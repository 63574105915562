body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-bottom: calc(4px + env(safe-area-inset-top));
}

.carousel .control-next.control-arrow:before {
  border-left: 25px solid white;
}
.carousel .control-prev.control-arrow:before {
  border-right: 25px solid white;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

@font-face {
  font-family: MTD;
  src: url(assets/Tilde\ -\ Cooper\ TL\ Black.ttf);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto;
}
