.flagImage {
  max-width: 100%;
  max-height: 100%;
}

.flagContainer {
  margin-top: 5px;
  max-width: 45px;
}

@media (min-width: 900px) {
  .flagContainer {
    margin-top: 15px;
  }
}
