.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

:root {
  --mtdBlue: #007bff;
  --mtdDisabledBlue: #27bdff;
}

.MuiCardActions-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#postContainer img {
  max-width: 80%;
}

/*TODO: Por algún motivo no funciona en un css aparte */
.rce-mbox-photo--img {
  max-height: 500px !important;
}

.menu:hover {
  background-color: #1291fc;
}

.rce-mbox-photo--img {
  width: 250px !important;
  height: 250px !important;
  object-fit: contain !important;
  overflow: hidden !important;
}

@media only screen and (min-width: 900px) {
  .rce-mbox-photo--img {
    width: 350px !important;
    height: 350px !important;
    object-fit: contain !important;
    overflow: hidden !important;
  }
}

.rce-mbox-text {
  font-size: 18px !important;
}

